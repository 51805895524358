<template>
 
  <div>
    <div class="process_detail-page" v-title data-title="我的助手账号绑定"></div>
   <div class="logo">
    <img src="../../assets/xxzslogo.png" alt="">
    <p>我的助手</p>
   </div>
   <div class="box">
       <div class="input Verification Code" >
        <div class="phone">
           <van-field class="accountNumber" v-model="tel" type="tel"  @input="input()"   @blur="b()" placeholder="请输入用户手机号"/>
        </div>
       
        <div class="pass">
         <van-field class="password"  v-model="password"  maxlength="4" type="tel"  placeholder="请输入验证码"/>
         <div class="right" @click='sendout'>  
           <span v-show="shows" @click="sendout">获取验证码</span>
           <span v-show="!shows" class="count">{{count}} 秒</span>
         </div>
       </div>
         <div class="user">
          <div class="img" v-if="user.nickname!=undefined&&user.nickname!=''">
            <img :src="user.avatar" alt="">
            客户昵称：{{user.nickname}}
          </div>
          <div class="img" v-else>
            <img src="../../assets/touxiang.png" alt="">
            客户昵称：暂无
          </div>
      
         
         </div>
      <van-button round type="primary"  @click="tijiao()">立即绑定</van-button>
   </div>
  </div>
  <!-- <van-cell is-link @click="showPopup">展示弹出层</van-cell> -->
  <van-popup v-model="show"  class="tankuang" overlay-class="overlay">
      <p  class="conte" v-if="this.tiaozhuans">此账号未注册<br>请下载我的助手APP进行注册</p>
      <p  class="conte"  v-if="!this.tiaozhuans">
       您已成功绑定,请关闭此页面 </p>
      <p class="btn"  @click="chenggong()"  v-if="!this.tiaozhuans">
        <span>确定</span> </p>
      <p class="btn"  v-if="this.tiaozhuans">
        <span  class="a" @click="chenggong()">取消</span> 
        <span  @click="tiaozhuan()">前往下载</span> 
       
      </p>
  </van-popup>
</div>
</template>

<script>
import request from "../../plugin/request";
import { Toast } from 'vant';
import { ref } from 'vue';
export default {
  
   data() {
    return {
      tiaozhuans:false,
      show: false,
      code:'',
      strs:'',
      tel:'',
      shows: true,
      count: '',
      password:'',
      user:{
        nickname:'',
        avatar:''
      },

    }
   },
   mounted() {
    this.$nextTick(() => {
    
    this.a();
  });
   },
   methods: { 
    tiaozhuan() {
      window.location.href = 'https://a.app.qq.com/o/simple.jsp?pkgname=com.zixin.studyassistant'
    },
    chenggong() {
      this.show=false
    },
    showPopup() {
      this.show = true;
    },
    
    b() {
       if(this.user.nickname==undefined||this.user.nickname=='') {
        this.tiaozhuans=true
        this.showPopup()
       }
    },
    async input() {
      const data=await request.post("app/user/getUserInfoByMobile", {
         mobile:this.tel,
       });    
      console.log(data.data)
      this.user.nickname=data.data.nickname
      this.user.avatar=data.data.avatar
      console.log(this.user)
    },
    getCode(){
 const TIME_COUNT = 60;
 if (!this.timer) {
 this.count = TIME_COUNT;
 this.shows = false;
 this.timer = setInterval(() => {
 if (this.count > 0 && this.count <= TIME_COUNT) {
  this.count--;
  } else {
  this.shows = true;
  clearInterval(this.timer);
  this.timer = null;
  }
 }, 1000)
 }
} ,
    async sendout() {
      if(!(/^1[3456789]\d{9}$/.test(this.tel))){ 
        Toast('请输入正确手机号码');  
        return
     } else {

      this.getCode()
      const data = await request.post("app/sms/sendBindWxopenidCode", {
         mobile:this.tel,
       });    
      console.log(data)
     }
     
    },
      a() {
        this.code=window.location.href
        console.log(this.code)
        this.strs = this.code.slice(34,66);//截取第二个到第四个之间的字符 cd
        console.log(this.strs)

      },

      tijiao() {
        if(this.strs!=''||this.tel!='') {
          this.sub()
        }else{
          Toast('请输入正确手机号码！');
        }
      },
    
    async sub() {
      const data = await request.post('/app/user/bindWxOpenid',{ 
        smsCode:this.password,
        code:this.strs,
        tel:this.tel
      })
      console.log(data)
      if(data.status==true) {
        this.tiaozhuans=false
        this.showPopup()
      } else {
        Toast('验证码输入错误，请重新输入！');
      }
   
    }

  }
}
</script>

<style lang="less">
.van-overlay {
  background-color: rgba(0,0,0,.3)
}
.tankuang {
  border-radius:.13rem;

  width: 75%;
   .conte {
    padding:.96rem 1.17rem  .69rem;
    box-sizing: border-box;
    margin:0;
    text-align: center;
    font-size:.37rem;
    line-height: 1.5;
   }

   .btn {
    width: 100%;
    text-align: center;
    font-size: .37rem;
    color:#2397FF ;
    // padding:.35rem 0;
    margin:0;
    border-top: 1px solid #d8d8d8;

    span {
      display: inline-block;
      width: 50%;
      text-align: center;
      padding:.35rem 0;
    }

    .a {
      border-right:1px solid #d8d8d8;
      box-sizing: border-box;
      color: rgba(0,0,0,0.2);
    }
   }
}
.phone {
  width: 100%;
  position: relative;
  border-bottom:.03rem solid #D8D8D8;
}
.pass {
  position: relative;
  width: 100%;
}
    .Verification {
      overflow: hidden;
     .van-cell,.van-field {
      padding-left:0;
         width: 7.88rem;
         height: 1.12rem;
        //  background: url(../../assets/dlzhsr.png);
         background-size: 100% 100%;
         margin-top:.8rem;
         border-bottom:none;
        //  background-color: #ebeff2;
      }
      .van-cell::after{
        border-bottom:none;
      }
      .accountNumber .van-cell__title {
         width: .48rem;
         height: .57rem;
         background: url(../../assets/dlzh.png);
         background-size: 100% 100%;
         span {
           display: none;
          }
       }
       .button, input, textarea {
        font-size: .4rem;
       }
       .password  {
         width: 4.8rem;
        //  background: url(../../assets/dlyzmk.png);
         background-size: 100% 100%;
         float: left;
         margin-top:.4rem;
         padding-right: 0;

     
       }
       .right {
         float: right;
         width: 3.03rem;
         height: 1.19rem;
        
        //  background: url(../../assets/dlyzmfs.png);
         background-size: 100% 100%;
         margin-top:.4rem;
         margin-left: .12rem;
         text-align: right;
         line-height: 1.19rem;
         color: #2397FF;
         font-size: .43rem;
       }
       .password  .van-cell__title {
         width: .44rem;
         height: .57rem;
         background: url(../../assets/dlmm.png);
         background-size: 100% 100%; 
         font-size: .43rem;
         span {
           display: none;
          }
       }
    }
.logo {
  width:100%;
  margin-top: 2rem;
  margin-bottom:1.10rem;
 
    img {
      width:1.6rem;
      height:1.6rem;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      border-radius: .14rem;
    }
    p {
      width: 100%;
      font-size:.48rem ;
      padding:0;
      margin-top:.13rem;
      text-align: center;
      font-weight: 600;
    }
}
.box {
  padding:0 .4rem;
  .van-cell-group {
    padding:0;
    margin:0;
    .van-cell  {
      padding-left:0;

      .van-field__control {
        font-size:.43rem;
        font-weight: 200;
      }
    }
  }
  .user {
    display: inline-block;
    padding-top:.30rem;
    width: 100%;
    border-top: .03rem solid #D8D8D8;
    .img {
      
      display: inline-block;
      font-size:.37rem;
      img {
        width:.96rem ;
        height: .96rem;
        vertical-align: middle;
        border-radius: 50%;
        overflow: hidden;
       
      }
    }

    .name {
     
      display: inline-block;
    }
  }
  .van-button {
    width: 100%;
    height: 1.2rem;
    border-radius: 10px;
    background: #2397FF;
    font-size:.41rem;
    color: #FFFFFF;
    font-weight: 400;
    margin-top:.5rem;
    border: 0;
  }
}

</style>